import React, { useState } from 'react'
import { useField } from '../hooks/'
import { Input } from './NewRegister/Formelements'
import axios from 'axios'

const CheckTicket = () => {

  const code = useField("text", "")
  const [data, setData] = useState({})
  const [error, setError] = useState("")

  const check = () => {
    const token = localStorage.getItem('token')
    const url = '/invitations/checkCode'
    const config = {
      headers: { authorization: "bearer " + token }
    }

    axios
      .post(url, { code: code.value }, config)
      .then(res => {
        setData(res.data)
        console.log(res.data)
      })
      .catch(error => {
        console.log(error.response)
        setError(error.response.data.error)
      })
  }

  const createField = (key, value, format) => {
    if (format === "time") {
      const date = new Date(value)
      value = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " | " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2)
    }
    if (format === "status") {
      switch(value) {
      case "used":
        value = "Käytetty"
        break
      case "used_recently":
        value = "Käytetty vähän aikaa sitten"
        break
      case "not_used":
        value = "Ei käytetty"
        break
      default:
        value = "Ei käytetty"
      }
    }
    return (
      <div className="fieldset">
        <label htmlFor="code" >{key}:</label>
        <h4>{value}</h4>
      </div>
    )
  }

  return (
    <div className="whiteBox">
      <h1>Tarkista lippu</h1>
      <Input inputHook={code} message={{ id: "invitations.code" }} />
      <button className="login" onClick={check}>Tarkasta</button>
      {error &&
        <div className="errors">{error}</div>
      }
      {data.code &&
        <div className="datafield" >
          <h2>Lipun tiedot</h2>
          {createField("Koodi", data.code, null)}
          {createField("Luotu", data.createdAt, "time")}
          {createField("Tapahtumadi", data.event.name, null)}
          {createField("Yritys", data.organization.organization_name, null)}
          {createField("Yritys email", data.organization.email, null)}
          {createField("Status", data.status, "status")}
          {createField("Viimeisin päivitys", data.updatedAt, "time")}
          {data.reads &&
            <>
              <h2>Lukemiset</h2>
              {
                data.reads.map((r, i) =>
                  <div className="ticketRead" key={i}>
                    {createField("Lukija", r.user, null)}
                    {createField("Aika", r.timestamp, "time")}
                  </div>
                )
              }
            </>
          }
        </div>
      }
    </div>
  )
}

export default CheckTicket