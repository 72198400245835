import React, { useEffect, useState, lazy, Suspense } from 'react'
import axios from 'axios'
import { AdminContext, RegisterContext } from './context/'
import NoEvents from './components/NoEvents'

import SuccessPage from './components/SuccessPage'
import Unauthorized from './components/Unauthorized'
import { Route, useParams, useLocation } from "react-router-dom"
import CheckTicket from './components/CheckTicket'
import ErrorBoundary from './ErrorBoundary'
const EditRegistration = lazy(() => import('./components/NewRegister/EditRegistration'))
const Login = lazy(() => import('./components/Login'))
const Nav = lazy(() => import('./components/Nav'))
const Events = lazy(() => import('./components/Events'))
const Event = lazy(() => import('./components/Event'))
const Organizations = lazy(() => import('./components/Organizations'))
const Organization = lazy(() => import('./components/Organization'))
const ViewRegistration = lazy(() => import('./components/ViewRegistration'))
const Index = lazy(() => import('./components/Index'))
const Main = lazy(() => import('./components/Main'))
const NewRegister = lazy(() => import('./components/NewRegister/'))
const ContactForm = lazy(() => import('./components/ContctForm'))
const Configuration = lazy(() => import('./components/Configuration'))
const NewEvent = lazy(() => import('./components/NewEvent'))

const Invitations = lazy(() => import('./components/Invitatioons'))

let login = false

const Redirect = () => {
  let p = window.location.pathname
  if (p.includes("/fi/")) {
    p = p.split("/fi/").join("/")
    localStorage.setItem("language", "fi")
    window.location = p
  }
  if (p.includes("/en/")) {
    p = p.split("/en/").join("/")
    localStorage.setItem("language", "en")
    window.location = p
  }
  if (p.includes("/sv/")) {
    p = p.split("/sv/").join("/")
    localStorage.setItem("language", "sv")
    window.location = p
  }
  const { token } = useParams()
  localStorage.setItem('token', token)
  window.location = '/'
}

function App() {

  const [config, setConfig] = useState({})
  const [admin, setAdmin] = useState(false)
  const [form, setForm] = useState({})
  const location = useLocation()

  useEffect(() => {
    axios.get('/config')
      .then(res => {
        setConfig(res.data.config)
      })
      .catch(error => {
        console.log(error)
      })
    const l = localStorage.getItem("language")
    if (l) document.documentElement.lang = l
  }, [])

  if (localStorage.getItem('token')) login = true
  let p = window.location.pathname
  if (p.includes("/fi/")) {
    p = p.split("/fi/").join("/")
    localStorage.setItem("language", "fi")
    window.location = p
  }
  if (p.includes("/en/")) {
    p = p.split("/en/").join("/")
    localStorage.setItem("language", "en")
    window.location = p
  }
  if (p.includes("/sv/")) {
    p = p.split("/sv/").join("/")
    localStorage.setItem("language", "sv")
    window.location = p
  }
  if (login && localStorage.getItem('redirect') !== null) {
    const path = localStorage.getItem('redirect')
    localStorage.removeItem('redirect')
    window.location.pathname = path
  }
  if (!login && (p.includes('registertoevent') || p.includes('viewregistration'))) {
    localStorage.setItem('redirect', window.location.pathname)
    window.location.pathname = "/"
  }
  return (
    <div className="App">
      <Suspense fallback={<></>} >
        <AdminContext.Provider value={[admin, setAdmin]}>
          <Nav login={login} config={config} />
          <ErrorBoundary key={location.pathname}>
            <Route exact path="/" component={() => login ? <Main config={config} setConfig={setConfig} /> : <Index config={config} />} />
            <Route path="/register/:id?" component={ContactForm} />
            <Route path="/login"><Login /></Route>
            <Route path="/noEvents"><NoEvents /></Route>
            <Route path="/registertoevent/:eventId?" component={NewRegister} ></Route>
            <RegisterContext.Provider value={[form, setForm]} >
              <Route path="/newreg/:eventId?" component={NewRegister} ></Route>
            </RegisterContext.Provider>
            <Route path="/check_ticket"><CheckTicket /></Route>
            <Route path="/invitationorders"><Invitations config={config} /></Route>
            <Route path="/events"><Events /></Route>
            <Route path="/viewevent/:eventId" component={Event} />
            <Route path="/newevent"><NewEvent /></Route>
            <Route path="/editevent/:id" component={NewEvent} />
            <Route path="/login/:lang?/:token"><Redirect /></Route>
            <Route path="/editregistration/:id" component={EditRegistration} type="view" />
            <Route path="/viewregistration/:id" component={ViewRegistration} />
            <Route path="/organizations"><Organizations /></Route>
            <Route path="/organization/:id" component={Organization} />
            <Route path="/success/:id/:eventName" component={SuccessPage} />
            <Route path="/403"><Unauthorized /></Route>
            <Route path="/configuration"><Configuration config={config} setConfig={setConfig} /></Route>
          </ErrorBoundary>
        </AdminContext.Provider>
      </Suspense>
    </div>
  )
}

export default App
