import { useState } from 'react'
import uuid from 'react-uuid'

export const useField = (type, defaultValue = '') => {
  const [value, setValue] = useState(defaultValue)

  const id = uuid()

  const onChange = (event) => {
    if(event === undefined) return
    if(typeof event === 'string'){
      setValue(event)
      return
    }
    if (!event.target) {
      setValue(event)
      return
    }
    setValue(event.target.value)
  }

  return {
    type,
    value,
    onChange,
    id
  }
}
