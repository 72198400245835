import React from 'react'

const Unauthorized = () => {
  return (
    <div className="whiteBox">
      <h1>403 Unauthorized</h1>
      <p>Yritit juuri päästä käsiksi tietoihin, joihin sinulla ei ole oikeutta.</p>
    </div>
  )
}

export default Unauthorized