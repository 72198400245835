import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
const SuccessPage = () => {

  let { eventName } = useParams()

  return (
    <div className="whiteBox">
      <h1><FormattedMessage id="success.header" values={{ event: eventName }}/></h1>
      <p><FormattedMessage id="success.text" /></p>
    </div>
  )
}

export default SuccessPage