import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { IntlProvider } from 'react-intl'
import { BrowserRouter as Router } from "react-router-dom"

import messages_fi from './translations/fi.json'
import messages_en from './translations/en.json'
import messages_sv from './translations/sv.json'

const messages = {
  'fi': messages_fi,
  'en': messages_en,
  'sv': messages_sv
}

const lang = localStorage.getItem('language') || 'fi'

ReactDOM.render(
  <IntlProvider locale={lang} messages={messages[lang]}>
    <Router><App /></Router>
  </IntlProvider>,
  document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister()
