import React from 'react'
import { FormattedMessage } from 'react-intl'

const NoEvents = () => {
  return(
    <div className="whiteBox">
      <h2>
        <FormattedMessage id="register.already_enrolled"
          defaultMessage="Olet jo ilmoittautunut tähän tapahtumaan tai ei ole tapahtumia, johon voit ilmoittautua"
          description="Register Olet jo ilmoittautunut" />
      </h2>
      <p>
        <FormattedMessage id="register.already_enrolled_descriptiotn"
          defaultMessage="Voit tarkastella omaa ilmoittautumistasi ylävalikon 'Oma ilmoittautuminen' -kohdasta."
          description="Register Olet jo ilmoittautunut desc" />
      </p>
    </div>
  )
}

export default NoEvents