import React from "react"
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    console.log(errorInfo)
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo && this.state.error) {
      let param = encodeURIComponent("Automaattisesti täytetty, ethän muokkaa: \n\n(ErrorBoundary)\n" + this.state.error.toString() + "\n" + this.state.errorInfo.componentStack + "\n\n" + window.location.href)
      return (
        <div className="whiteBox">
          <h2>Jotain meni pieleen.</h2>
          <p>Voit täyttää bugiraportin <a href={"https://docs.google.com/forms/d/e/1FAIpQLSeoYTM2Q6GxjJZlKMzt3glrdsfp4vDx3dXwmm2jzATv3FT32w/viewform?usp=pp_url&entry.394327531=" + param} rel="noreferrer" target="_blank">klikkaamalla tästä</a>.</p>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <br />
            {this.state.error && this.state.error.toString()}
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      )
    }
    return this.props.children
  }
}